@import '../prelude';

.ink-ripple {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 100;
    overflow: hidden;
    border-radius: inherit;

    .ink-single-ripple {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 50%;
        background: rgb(var(--md-ripple-color) / var(--md-ripple-color-opacity));
    }

    .ink-ripple-highlight {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(var(--md-ripple-highlight) / var(--md-ripple-highlight-opacity));
    }
}
